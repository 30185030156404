<template>
    <div style="display: inline-block;">

        <div class="upload-list" :style="{width: `${width}px`, height: `${height}px`}" v-for="(item, index) in uploadList" :key="index">
            <template v-if="item.status === 'finished'">
                <img :src="item.url">
                <div class="upload-list-cover" :style="{width: `${width}px`, height: `${height}px`}">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(index)"></Icon>
                </div>
            </template>
            <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
        </div>

        <Upload v-show="uploadList.length < size"
                ref="upload"
                :show-upload-list="false"
                :on-success="(res, file, fileList) => handleSuccess(res, file, fileList)"
                :format="types"
                :max-size="maxSize"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                :multiple="size > 1"
                :type="method"
                action="//upload.qiniup.com/"
                :data="{token: upToken}"
                :accept="accept"
                :style=uploadStyle>
            <div :style=uploadSlotStyle>
                <Icon type="ios-camera" size="20"></Icon>
            </div>
        </Upload>
        <image-preview ref="image-preview"></image-preview>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import {ToolResource} from "@/request/store/ToolResource";

    export default {
        name: "upload_image",
        props: {
            cdns: {
                type: Boolean,
                default: () => false
            },
            show: {
                type: Boolean,
                default: () => true
            },
            width: {
                type: Number,
                default: 78
            },
            height: {
                type: Number,
                default: 53
            },
            /**
             * 支持的文件类型
             */
            types: {
                type: Array,
                default: () => []
            },
            /**
             * 文件大小限制
             */
            maxSize: {
                type: Number,
                default: 1024
            },
            /**
             * 最大上传数量
             */
            size: {
                type: Number,
                default: 5
            },
            /**
             * 是否支持多个文件同时上传
             */
            multiple: {
                type: Boolean,
                default: () => false
            },
            /**
             * 支持方式，select：drag
             */
            method: {
                type: String,
                default: 'drag'
            },
            images: {
                type: Array,
                default: () => []
            },
            accept: {
                type: String,
                default: 'image/bmp,image/jpg,image/jpeg,image/png,image/gif'
            }
        },
        data() {
            return {
                upToken: '',
                uploadList: [],
                progress: 0
            }
        },
        mounted () {
            this.uploadList = this.$refs.upload.fileList;
        },
        computed: {
            uploadStyle() {
                return `display: inline-block;width: ${this.width}px; height:${this.height}`;
            },
            uploadSlotStyle() {
                return `width: ${this.width}px;height:${this.height}px;line-height: ${this.height}px;`;
            }
        },
        watch: {
            images() {
                this.uploadList = [];
                this.images.filter(j => j !== '' && j !== undefined).forEach(j => {
                    this.uploadList.push({
                        status: 'finished',
                        url: j,
                        showProgress: false
                    })
                })
            }
        },
        methods: {
            ...mapActions([
                'qiniuToken'
            ]),
            handleSuccess(res, file, fileList) {
                let self = this;
                file.url = (self.cdns? this.$cdns: this.$cdn) + res.key;
                /*let fileName = file.name;
                let type = '';
                if (fileName.indexOf('.') !== -1) {
                    type = fileName.substring(fileName.lastIndexOf('.') +1, fileName.length);
                }
                ToolResource.save({
                    key: res.key,
                    name: file.name,
                    resType: `image/${type}`,
                    size: file.size,
                    url: file.url
                }).then(body => {})*/
                this.$emit('on-success', file.url);
            },
            handleFormatError(file) {
                this.$Notice.warning({
                    title: '文件格式错误',
                    desc: `文件格式不正确，仅支持 ${this.types.join(',')}`
                });
            },
            handleMaxSize(file) {
                this.$Notice.warning({
                    title: '文件超出大小限制',
                    desc: `图片不得大于 ${this.maxSize}KB`
                });
            },
            handleBeforeUpload() {
                let self = this;
                return new Promise((resolve, reject) => {
                    let check = self.uploadList.length < self.size;
                    if (!check) {
                        self.$Notice.warning({
                            title: '文件超出数量限制',
                            desc: `${this.size}张图片已上传`
                        });
                        reject();
                    }
                    self.qiniuToken().then(token => {
                        self.upToken = token;
                        resolve();
                    });
                })
            },
            handleView(path) {
                this.$refs['image-preview'].show(path);
            },
            handleRemove(index) {
                this.$refs.upload.fileList.splice(index, 1);
                this.$emit('on-remove', index);
            }
        }
    }
</script>

<style scoped lang="less">

</style>
